var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-information","cancel-variant":"outline-secondary","ok-title":_vm.$t('Add'),"cancel-title":_vm.$t('Close'),"size":"lg","no-enforce-focus":"","ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Add information')},on:{"ok":_vm.confirmAdd,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"addInformationModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Name")))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.information.name),callback:function ($$v) {_vm.$set(_vm.information, "name", $$v)},expression:"information.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"name_en"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("English Name")))]),_c('validation-provider',{attrs:{"name":"name_en","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name_en","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.information.name_en),callback:function ($$v) {_vm.$set(_vm.information, "name_en", $$v)},expression:"information.name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"content"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Description")))]),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Editor',{attrs:{"api-key":_vm.API_KEY,"init":_vm.init,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.information.content),callback:function ($$v) {_vm.$set(_vm.information, "content", $$v)},expression:"information.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"banners"}},[_c('label',{attrs:{"for":""}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Banner"))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"banners","rules":"required|size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"banners","multiple":"","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.information.banners),callback:function ($$v) {_vm.$set(_vm.information, "banners", $$v)},expression:"information.banners"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.information.banners.length > 0),expression:"information.banners.length > 0"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.information.banners = []}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }